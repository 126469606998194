/* Haitian Creole localization file for jQuery UI datepicker. Not available from jQuery, so we had
to get translations ourselves. */

( function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define( [ "../widgets/datepicker" ], factory );
	} else {

		// Browser globals
		factory( jQuery.datepicker );
	}
}( function( datepicker ) {

datepicker.regional.ht = {
	/* Currently only contains temporary stub values */
	closeText: "Fèt",
	prevText: "Anvan",
	nextText: "Apre / suivan",
	currentText: "Jodiya",
	monthNames: ["Janvye", "Fevriye", "Mas", "Avril", "Me", "Jen", "Jiyè", "Out", "Septanm", "Oktòb", "Novanm", "Desanm" ],
	monthNamesShort: ["Jan", "Fev", "Mas", "Avr", "Me", "Jen", "Jiyè", "Out", "Sep", "Okt", "Nov", "Des" ],
	dayNames: [ "Dimanch", "Lendi", "Madi", "Mèkredi", "Jedi", "Vandredi", "Samdi" ],
	dayNamesShort: [ "Dim", "Len", "Madi", "Mèk", "edi", "Van", "Sam" ],
	dayNamesMin: [ "Di", "Len", "Ma", "Mè", "Je", "Van", "Sa" ],
	weekHeader: "Semenn"
};
datepicker.setDefaults( datepicker.regional.ht );

return datepicker.regional.ht;

} ) );
